
import { Component, Vue } from 'vue-property-decorator'

import { RouteName } from '@/modules/events/config'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
@Component({ name: 'GoToMap' })
export class GoToMap extends Vue {
  public async goToMap (): Promise<void> {
    await this.$router.push({ name: `events.${RouteName.Map}` })
  }
}
export default GoToMap
