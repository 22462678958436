import { VueConstructor } from 'vue'

import { Basic } from './versions/Basic.vue'
import { Promoted } from './versions/Promoted.vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export enum EventCardVersion {
  Basic = 'basic',
  Promoted = 'promoted'
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const eventCardVersionsRegistry: Record<EventCardVersion, VueConstructor> = {
  [EventCardVersion.Basic]: Basic,
  [EventCardVersion.Promoted]: Promoted
}