
import { Component, Vue } from 'vue-property-decorator'

import {
  IWishlistService,
  WishlistBucket,
  WishlistItem,
  WishlistServiceType
} from '../../services/wishlist'

@Component
export class WishlistMixin extends Vue {
  protected readonly wishlistService =
    this.$container.get<IWishlistService>(WishlistServiceType)

  public get wishlist (): WishlistBucket | null {
    return this.$store.getters['favourites/wishlist']
  }

  public add<I> (item: WishlistItem<I>) {
    this.wishlistService.add(item)
    this.setWishlist()
  }

  public remove (id: number) {
    this.wishlistService.removeById(id)
    this.setWishlist()
  }

  protected setWishlist(): void {
    this.$store.commit('favourites/setWishlist', this.wishlistService.retrieve())
  }
}
export default WishlistMixin
