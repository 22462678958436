
import { Component, Prop, Vue } from 'vue-property-decorator'

import { EventModel } from '../../../models'
import { EventCard } from '../../../components/organisms/EventCard'

import { carouselConfig } from './PromotedEventsList.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'PromotedEventsList', components: { EventCard } })
export class PromotedEventsList extends Vue {
  @Prop({ type: Array, required: false, default: () => ([]) })
  public readonly events!: Array<EventModel>

  public readonly config = carouselConfig
}
export default PromotedEventsList
