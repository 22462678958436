
import { Component, Inject, Vue } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

import { AsyncDataContext } from '@/shared'
import { GoToMap } from '@/shared/components/molecules/GoToMap'
import { AmplitudeType, AppAmplitudeEvent, IAmplitude } from '@/shared/services/amplitude'
import { AnyObject, Pageable } from '@/core/general'

import { EventsRepository } from '@/modules/events/repositories'
import { EventsList } from '@/modules/events/components/organisms/EventsList'
import { PromotedEventsList } from '@/modules/events/components/organisms/PromotedEventsList'
import { EventsGroupsCollection } from '@/modules/events/components/organisms/EventsGroupsCollection'
import { EventModel, EventsGroupModel } from '@/modules/events/models'
import { EventsRepositoryType, IEventsRepository } from '@/modules/events/contracts'

import { NoEventsBanner } from '../components/molecules/NoEventsBanner'
import { CitySelectorBadge } from '../components/organisms/CitySelectorBadge'
import { MainViewAsyncDataReturns } from '../contracts/data'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
@Component<MainView>({
  name: 'MainView',
  components: { EventsList, PromotedEventsList, GoToMap, NoEventsBanner, EventsGroupsCollection, CitySelectorBadge },
  async asyncData (context: AsyncDataContext): Promise<MainViewAsyncDataReturns> {
    const eventsRepository = context.app.$container.get<IEventsRepository>(EventsRepositoryType)

    try {
      const eventsData = await eventsRepository.fetchEvents(undefined, undefined,
        { 'filter_by_current_city': true }
      )
      const promotedEventsData = await eventsRepository.fetchPromotedEvents()
      const eventsGroupsCollectionData = await eventsRepository.fetchGroupedEventsCollection()

      return {
        eventsData,
        promotedEventsData,
        eventsGroupsCollectionData
      }
    } catch (e) {
      throw new Error(`[MainView.asyncData()]: Error: ${(e as Error).message}`)
    }
  },
  mounted (): void {
    this.boot()
  }
})
export class MainView extends Vue {
  @Inject({ from: 'isMobile', default: false })
  public isMobile!: () => boolean

  // IoC Injections.
  private readonly eventsRepository =
    this.$container.get<EventsRepository>(EventsRepositoryType)

  private readonly amplitude =
    this.$container.get<IAmplitude>(AmplitudeType)

  public eventsData!:  Pageable<EventModel>
  public promotedEventsData!:  EventModel[]
  public eventsGroupsCollectionData!: EventsGroupModel[]

  public events: Pageable<EventModel> | null = null
  public promotedEvents: EventModel[] | null = null
  public eventsGroupsCollection: EventsGroupModel[] | null = null

  public get hasPromotedEvents (): boolean {
    return !!this.promotedEvents && this.promotedEvents.length > 0
  }

  public get hasEventsGroupsCollection (): boolean {
    return this.eventsGroupsCollectionData && this.eventsGroupsCollectionData.length > 0
  }

  protected boot (): void {
    this.amplitude.emit(AppAmplitudeEvent.ON_MAIN_PAGE)
    this.events = {
      ...this.eventsData!,
      items: this.eventsData!.items.map((item: EventModel) => {
        return EventModel.hydrate(item)
      })
    }

    this.promotedEvents = this.promotedEventsData?.map((item: EventModel) => {
      return EventModel.hydrate<EventModel>(item)
    }) ?? []

    this.eventsGroupsCollection = this.eventsGroupsCollectionData.map((item: EventsGroupModel) => {
      return EventsGroupModel.hydrate(item)
    }) ?? []
  }


  /**
   * Handles @loadMore event of EventsList component.
   */
  public async onLoadMoreClicked () {
    if (this.events?.nextPage) {

      // Update currentPage in state.
      this.$store.commit('root/setCurrentPage', this.events.nextPage)

      let eventsCopy = cloneDeep(this.events)
      const nextPageEvents = await this.eventsRepository.fetchEvents(this.events.nextPage,
        undefined, { 'filter_by_current_city': true })

      eventsCopy = {
        ...eventsCopy,
        items: [...eventsCopy.items, ...nextPageEvents.items],
        nextPage: nextPageEvents.nextPage,
        previousPage: nextPageEvents.previousPage
      }

      this.events = eventsCopy
    }
  }

  public async onFilters (filters: AnyObject): Promise<void> {
    this.events = await this.eventsRepository.fetchEvents()
  }
}

export default MainView
