import { CarouselConfig } from '@/dsl/molecules/Carousel/Carousel.contracts'

export const carouselConfig: CarouselConfig = {
  slidesPerView: 1,
  loop: false,
  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
  },
}