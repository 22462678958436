
import { Component, Prop, Vue } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import { EventModel } from '../../../models'
import { EventDetail } from '../../molecules/EventDetail'

import { EventCardVersion, eventCardVersionsRegistry } from './EventCard.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'EventCard', components: { EventDetail } })
export class EventCard extends Vue {
  @Prop({ type: Object, required: true })
  public readonly event!: EventModel

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isPromoted!: boolean

  /**
   * Determines the component that should be used to represent the event card.
   */
  public get component (): VueConstructor {
    if (this.isPromoted) {
      return eventCardVersionsRegistry[EventCardVersion.Promoted]
    }

    return eventCardVersionsRegistry[EventCardVersion.Basic]
  }
}
export default EventCard
