
import { Component, Prop } from 'vue-property-decorator'

import { WishlistMixin } from '@/modules/favourites/shared/mixins/WishlistMixin.vue'

import { EventModel } from '../../../../models'
import { EventDetail } from '../../../molecules/EventDetail'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<Basic>({
  name: 'Basic',
  components: { EventDetail },
})
export class Basic extends WishlistMixin {
  @Prop({ type: Object, required: true })
  public readonly event!: EventModel

  public get thumbnail (): string | undefined {
    return Array.isArray(this.event.get('images')) && this.event.get('images').length > 0 ?
      this.event.get('images')[0].file : undefined
  }

  public async goToEvent (): Promise<void> {
    await this.$router.push({
      name: 'events.event',
      params: { slug: this.event.get('slug') }
    })
  }

  public get isFavourite (): boolean {
    if (!this.wishlist) {
      return false
    }

    return this.wishlist.items.some(item => item.id === this.event.get('id'))
  }

  public toggleFavourite (): void {
    if (this.isFavourite) {
      this.remove(this.event.get('id'))
    } else {
      this.add({
        id: this.event.get('id'),
        validUntil: this.event.get('startDate'),
        item: this.event.toObject()
      })
    }
  }
}

export default Basic
