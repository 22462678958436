
import { Component, Prop, Vue } from 'vue-property-decorator'

import { EventsGroupModel } from '../../../models'
import { EventsGroup } from '../../../components/molecules/EventsGroup'

import { carouselConfig } from './EventsGroupsCollection.config'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
@Component<EventsGroupCollection>({
  name: 'EventsGroupCollection',
  components: { EventsGroup }
})
export class EventsGroupCollection extends Vue {
  @Prop({ type: Array, required: false, default: () => [] })
  public readonly groups!: EventsGroupModel[]

  public readonly config = carouselConfig
}
export default EventsGroupCollection
