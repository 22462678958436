
import { Component, Prop, Vue } from 'vue-property-decorator'
import { EventsGroupModel } from '../../../models'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
@Component({ name: 'EventsGroup' })
export class EventsGroup extends Vue {
  @Prop({ type: Object, required: true })
  public readonly eGroup!: EventsGroupModel

  public get collectionSlug (): string {
    return this.eGroup.get('url')?.split('/')[1] ?? ''
  }
}
export default EventsGroup
