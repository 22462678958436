
import { Component, Vue } from 'vue-property-decorator'

import { DrawerServiceType, IDrawer } from '@/core/services/drawer'

import { RootModuleDrawersCollection } from '../../../config'
import { IRootService, RootServiceType } from '../../../services/root'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
@Component({ name: 'CitySelectorBadge' })
export class CitySelectorBadge extends Vue {
  protected readonly rootService =
    this.$container.get<IRootService>(RootServiceType)

  protected readonly drawerService =
    this.$container.get<IDrawer>(DrawerServiceType)

  public get selectedCity (): string {
    return this.rootService.getProperty('city') ?? '---'
  }

  public openCitySelectorDrawer (): void {
    this.drawerService.show(RootModuleDrawersCollection.CityChoices)
  }
}

export default CitySelectorBadge
