
import { Component, Prop, Vue } from 'vue-property-decorator'

import { EventModel } from '../../../../models'

import { EventDetail } from '../../../molecules/EventDetail'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'Promoted', components: { EventDetail } })
export class Promoted extends Vue {
  @Prop({ type: Object, required: true })
  public readonly event!: EventModel

  public get thumbnail (): string | undefined {
    return Array.isArray(this.event.get('images')) && this.event.get('images').length > 0 ?
      this.event.get('images')[0].file : undefined
  }

  public async goToEvent (): Promise<void> {
    await this.$router.push({
      name: 'events.event',
      params: { slug: this.event.get('slug') },
    })
  }
}
export default Promoted
