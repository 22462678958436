import { CarouselConfig } from '@/dsl/molecules/Carousel/Carousel.contracts'

export const carouselConfig: CarouselConfig = {
  slidesPerView: 5,
  loop: false,
  breakpoints: {
    1200: {
      slidesPerView: 5,
    },
    967: {
      slidesPerView: 3
    },
    800: {
      slidesPerView: 2.5
    },
    500: {
      slidesPerView: 2
    },
    250: {
      slidesPerView: 1.4

    }
  },
  navigationButtons: {
    prevEl: '.swiper-button-prev',
    nextEl: '.swiper-button-next'
  },
  pagination: false
}