
import { Component, Inject, Mixins } from 'vue-property-decorator'

import { AnyObject } from '@/core/general'
import { IDrawer, DrawerServiceType } from '@/core/services/drawer'
import { IModal, ModalServiceType } from '@/core/services/modal'

import { AmplitudeType, AppAmplitudeEvent, IAmplitude } from '@/shared/services/amplitude'
import { FilterAware } from '@/shared/components/molecules/Filter'

import {
  EventsModuleDrawersCollection,
  EventsModuleModalsCollection
} from '@/modules/events/config'

import { IRootService, RootServiceType } from '../../../services/root'

@Component({ name: 'NoEventsBanner' })
export class NoEventsBanner extends Mixins<FilterAware>(FilterAware) {
  @Inject({ from: 'isMobile', default: false })
  public isMobile!: () => boolean

  /**
   * Service instance
   */
  protected readonly rootService: IRootService =
    this.$container.get(RootServiceType)

  protected readonly modalService: IDrawer =
    this.$container.get<IModal>(ModalServiceType)

  protected readonly amplitude =
    this.$container.get<IAmplitude>(AmplitudeType)

  protected readonly drawerService: IDrawer =
    this.$container.get<IDrawer>(DrawerServiceType)

  /**
   * Determines the city of the user.
   */
  public get city (): string {
    return this.rootService.getProperty<string>('city')
  }

  public openFilters (): void {
    this.amplitude.emit(AppAmplitudeEvent.ON_OPEN_FILTERS)
    if (this.isMobile()) {
      this.openFiltersDrawer()
    } else {
      this.openFiltersModal()
    }
  }

  protected openFiltersDrawer (): void {
    this.drawerService.show(EventsModuleDrawersCollection.FiltersDrawer, {
      appliedFilters: this.appliedFilters,
      onApply: this.onApplyFilters,
      shouldRedirect: true
    })
  }

  protected openFiltersModal (): void {
    this.modalService.show(EventsModuleModalsCollection.FiltersModal, {
      appliedFilters: this.appliedFilters,
      onApply: this.onApplyFilters,
      shouldRedirect: true
    })
  }

  /**
   * Determines the callback for FiltersDrawer's apply action.
   */
  protected onApplyFilters(filters: AnyObject): void {
    this.amplitude.emit(AppAmplitudeEvent.ON_APPLY_FILTERS, {
      filters
    })

    this.queryParams = {
      ...this.queryParams,
      ...filters
    }
  }
}
export default NoEventsBanner
