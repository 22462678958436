
import { Component, Inject, Mixins, Prop } from 'vue-property-decorator'

import { AnyObject, Pageable } from '@/core/general'
import { DrawerServiceType, IDrawer } from '@/core/services/drawer'
import { IModal, ModalServiceType } from '@/core/services/modal'

import { IRootService, RootServiceType } from '@/modules/root/services/root'
import { FilterAware } from '@/shared/components/molecules/Filter'
import { AmplitudeType, AppAmplitudeEvent, IAmplitude } from '@/shared/services/amplitude'

import { EventsModuleDrawersCollection, EventsModuleModalsCollection } from '../../../config'
import { EventModel } from '../../../models'

import { EventCard } from '../EventCard'

/**
 * @author Javlon Khalimjonov <khalimjanov2000@gmail.com>
 */
@Component<EventsList>({
  name: 'Events',
  components: { EventCard }
})
export class EventsList extends Mixins<FilterAware>(FilterAware) {
  @Inject({ from: 'isMobile', default: false })
  public isMobile!: () => boolean

  @Prop({ type: Object, required: false, default: () => ({}) })
  public readonly events!: Pageable<EventModel>

  protected readonly drawerService: IDrawer =
    this.$container.get<IDrawer>(DrawerServiceType)

  protected readonly modalService: IDrawer =
    this.$container.get<IModal>(ModalServiceType)

  protected readonly rootService: IRootService =
    this.$container.get<IRootService>(RootServiceType)

  protected readonly amplitude =
    this.$container.get<IAmplitude>(AmplitudeType)

  public openFiltersDrawer (): void {
    this.drawerService.show(EventsModuleDrawersCollection.FiltersDrawer, {
      appliedFilters: this.appliedFilters,
      onApply: this.onApplyFilters,
      shouldRedirect: true
    })
  }

  public openFiltersModal (): void {
    this.modalService.show(EventsModuleModalsCollection.FiltersModal, {
      appliedFilters: this.appliedFilters,
      onApply: this.onApplyFilters,
      shouldRedirect: true
    })
  }

  public openFilters (): void {
    this.amplitude.emit(AppAmplitudeEvent.ON_OPEN_FILTERS)
    if (this.isMobile()) {
      this.openFiltersDrawer()
    } else {
      this.openFiltersModal()
    }
  }

  /**
   * Determines the callback for FiltersDrawer's apply action.
   */
  protected onApplyFilters(filters: AnyObject): void {
    this.amplitude.emit(AppAmplitudeEvent.ON_APPLY_FILTERS, {
      filters
    })

    this.queryParams = {
      ...this.queryParams,
      ...filters
    }
  }

  public loadMore (): void {
    this.$emit('loadMore')
  }

  public get filters (): any {
    return this.rootService.getProperty('filters')
  }
}
export default EventsList
