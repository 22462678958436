
import { Component, Prop, Vue } from 'vue-property-decorator'

import { EventDetailProps } from './EventDetail.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name : 'EventDetail'})
export class EventDetail extends Vue {
  /**
   * @see EventDetailProps.title
   */
  @Prop({ type: String, required: true })
  public readonly title!: EventDetailProps['title']

  /**
   * @see EventDetailProps.description
   */
  @Prop({ type: String, required: false })
  public readonly description?: EventDetailProps['description']

  /**
   * @see EventDetailProps.description
   */
  @Prop({ type: String, required: false })
  public readonly icon?: EventDetailProps['icon']
}
export default EventDetail
